import { apiClientAuth } from "@/utils/apiClient"

export default {
  getFlks(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/applicant',
      ...payload
    })
  },
  getNoReviewedFlks(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/applicant/not-reviewed',
      ...payload
    })
  },
  getFlk(id, payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/applicant/' + id,
      ...payload
    })
  },
  generateToken(payload) {
    return apiClientAuth({
      method: 'post',
      url: '/api/superadmin/flk/token/generate',
      ...payload
    })
  },
  exportFlk(id, payload) {
    return apiClientAuth({
      method: 'post',
      url: '/api/superadmin/flk/export/' + id,
      responseType: "blob",
      ...payload
    })
  },
  updateFlk(id, payload) {
    return apiClientAuth({
      method: 'post',
      url: '/api/superadmin/flk/edit/' + id,
      ...payload
    })
  },
  reviewFlk(id, payload) {
    return apiClientAuth({
      method: 'put',
      url: '/api/superadmin/applicant/set-reviewed/' + id,
      ...payload
    })
  },
  unreviewFlk(id, payload) {
    return apiClientAuth({
      method: 'put',
      url: '/api/superadmin/applicant/set-not-reviewed/' + id,
      ...payload
    })
  },
  deleteFlks(payload) {
    return apiClientAuth({
      method: 'delete',
      url: '/api/superadmin/applicant',
      ...payload
    })
  }
}
import { apiClientGuest } from '@/utils/apiClient'

export default {
  searchJobVacancy(payload) {
    return apiClientGuest({
      method: 'get',
      url: '/api/job-vacancies/search',
      ...payload
    })
  },
}
import { apiClientAuth } from '@/utils/apiClient'

export default {
  getJobVacancies(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/job-vacancies',
      ...payload
    })
  },
  getOngoingJobVacancies(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/job-vacancies/on-going',
      ...payload
    })
  },
  getJobVacancy(id, payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/job-vacancies/' + id,
      ...payload
    })
  },
  searchJobVacancy(payload) {
    return apiClientAuth({
      method: 'get',
      url: '/api/superadmin/job-vacancies/search',
      ...payload
    })
  },
  createJobVacancy(payload) {
    return apiClientAuth({
      method: 'post',
      url: '/api/superadmin/job-vacancies',
      ...payload
    })
  },
  updateJobVacancy(id, payload) {
    return apiClientAuth({
      method: 'put',
      url: '/api/superadmin/job-vacancies/' + id,
      ...payload
    })
  },
  deleteJobVacancies(payload) {
    return apiClientAuth({
      method: 'delete',
      url: '/api/superadmin/job-vacancies',
      ...payload
    })
  }
}